<template>
    <div class="yz-ab-wap">
       <erji-carousel></erji-carousel>
        <div class="ab-text">
            <div class="ab-title animate__bounceIn">
                <p>—— 关于我们 ——</p>
            </div>
            <div class="ab-desc animate__bounceIn">
                <p>
<!--                    全球互联网教育平台运营商，致力于构建终身教育生态系统-->
                </p>
            </div>
        </div>
        <div class="ab-con">
            <div class="ab-img"><img src="../assets/images/about-pic.jpg"></div>
            <div class="ab-item">
              <p>山东依智科技产业开发集团有限公司是伟东集团及昌邑市政府控股的子公司。伟东集团有限责任公司，于1998 年10 月由香港伟东置业有限公司在内陆投资设立，管理总部坐落于青岛。公司业务涵盖产业地产、商业不动产、云教育、大数据、金融投资、生物科技等领域，是一家多元化•全球化•平台化•协同化的综合性大型跨国集团企业，在全球16个国家设立分支机构，拥有50 多家全资及控股公司，2000 多位员工，其中海外员工600 多人，资产规模超过300 亿元。</p>
              <p>目前，伟东云教育已形成包括基础教育、职业教育、高等教育及海外教育业务在内的国际化教育集团，业务遍布全国20个省份、173个市区县，在全球16个国家及地区拥有分支机构，服务于330多家世界500强企业，用户数量超3000万。</p>
            </div>
        </div>
         <div style="clear:both"></div>
    </div>
</template>
<script>
import ErjiCarousel from '@/components/erjiCarousel.vue'
export default {
   components: {
      ErjiCarousel
  },
  data(){
    return{

    }
  }
}
</script>
<style lang="less" scoped>
   .yz-ab-wap{
       width: 100%;
       height: auto;
       .ab-lunbo{

       }
       .ab-text{
           margin-top:40px;
           .ab-title{
               text-align: center;
               font-size: 30px;
               color:#0184d2;
           }
           .ab-desc{
               margin-top:10px;
               text-align: center;
               font-size: 14px;
               color: #656d78;
           }
       }
        .ab-con{
            width: 1200px;
            margin:0 auto;
            clear: both;
            overflow: hidden;
            padding:50px 0px 50px 0px;
               .ab-img{
                   width:40%;
                   height: 300px;
                   float: left;
                     img{
                         width:100%;
                         height: 100%;
                     }
               }
               .ab-item{
                    width:58%;
                    height: 300px;
                    float: right;
                    p{
                        text-align: left;
                        text-indent: 30px;
                        margin-top:30px;
                        color:#000;
                        line-height: 25px;
                    }
               }
           }
   }
</style>
